import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueCompositionAPI from '@vue/composition-api'
import VueTextareaAutosize from 'vue-textarea-autosize'
import Oruga from '@oruga-ui/oruga'

Vue.config.productionTip = false

Vue.use(Oruga)
Vue.use(VueCompositionAPI)
Vue.use(VueTextareaAutosize)

import "windi.css"
// import '@oruga-ui/oruga/dist/oruga.css'
// import "./assets/css/tailwind.css"
// import './styles/kendo.scss'

new Vue({
  router,
  provide: {
    router
  },
  render: h => h(App)
}).$mount('#app')
