<script>
import {Vue} from 'vue-property-decorator'

export default class AppLayout extends Vue {
  get hasHeaderSlot() {
    return !!this.$slots.header
  }
  get hasFooterSlot() {
    return !!this.$slots.footer
  }
  get hasSidebarSlot() {
    return !!this.$slots.sidebar
  }
}
</script>

<template>
  <div id="app-layout" class="h-screen flex flex-col">
    <header v-if="hasHeaderSlot">
      <slot name="header" />
    </header>

    <main class="flex-1">
      <slot />
    </main>

    <aside v-if="hasSidebarSlot">
      <slot name="sidebar" />
    </aside>

    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>

    <portal-target name="overlays" />
  </div>
</template>
