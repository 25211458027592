<script>
import AppLayout from './components/app/AppLayout.vue'
import AppNavbar from './components/app/AppNavbar.vue'

export default {
  components: {AppLayout, AppNavbar},
}
</script>

<template>
  <app-layout>
    <template #header>
      <app-navbar />
    </template>

    <router-view />

    <template #footer>
      <footer class="bg-primary-300 p-6">Footer</footer>
    </template>
  </app-layout>
</template>
